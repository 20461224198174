import { SbBlokData } from "@storyblok/react";
import { FC } from "react";
import { DynamicSeo } from "seo";
import { StoryBaseProps } from "../types/StoryBaseProps";
import { StoryImageProps } from "../types/StoryImageProps";
import { DynamicComponentRepeater } from "./DynamicComponentRepeater";

export interface PageProps {
	title?: string;
	seo_image?: StoryImageProps;
	seo_description?: string;
	content?: SbBlokData[];
}

interface StoryPostProps extends StoryBaseProps<PageProps> {}

export const StoryPage: FC<StoryPostProps> = ({ blok }) => {
	const { title, seo_description, content } = blok;
	return (
		<>
			<DynamicSeo title={title} description={seo_description} />
			<DynamicComponentRepeater content={content} />
		</>
	);
};
